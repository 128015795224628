import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { apis } from '../app-apis';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import {
  AddBusinesResponseDTO,
  Business,
  BusinessUserRequest,
  BusinessUserRequestActionDTO,
  BusinessUserRequestDTO,
  IAddBusinessUserDTO,
  VerifyBusinesResponseDTO,
} from '../models/business/business.model';
import { IResponse } from '../models/response.model';
import {
  IHttpPagedResponse,
  ToastService,
} from '@irembo-andela/irembogov3-common';
import { convertObjectToQueryString } from '../helpers/query-param-converter';
import { IBaseBusinessPermissionAndGroupEntity } from '../models/business/business.enum';
import { IHttpSingleDataResponse } from '@irembo-andela/irembogov3-common';
import { IBusinessUser } from '../models/business/business-user.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class BusinessService {
  private baseUrl = environment.apiGatewayBaseUrl + '/application';
  private businessAPIs = apis.business;

  private iremboBusinessPermissions$: BehaviorSubject<
    IBaseBusinessPermissionAndGroupEntity[]
  > = new BehaviorSubject<IBaseBusinessPermissionAndGroupEntity[]>([]);

  iremboBusinessPermissionsObservable!: Observable<
    IBaseBusinessPermissionAndGroupEntity[]
  >;

  private iremboBusinessPermissionGroups$: BehaviorSubject<
    IBaseBusinessPermissionAndGroupEntity[]
  > = new BehaviorSubject<IBaseBusinessPermissionAndGroupEntity[]>([]);

  iremboBusinessPermissionGroupsObservable!: Observable<
    IBaseBusinessPermissionAndGroupEntity[]
  >;

  constructor(
    private http: HttpClient,
    private toastService: ToastService,
    private translateService: TranslateService
  ) {
    this.iremboBusinessPermissionsObservable =
      this.iremboBusinessPermissions$.asObservable();
    this.iremboBusinessPermissionGroupsObservable =
      this.iremboBusinessPermissionGroups$.asObservable();
  }

  getBusinesses(): Observable<IHttpPagedResponse<Business>> {
    return this.http.get<IHttpPagedResponse<Business>>(
      `${this.baseUrl}/${this.businessAPIs.listBusinesses}`
    );
  }

  getBusinessUsers(
    businessId: string
  ): Observable<IHttpPagedResponse<IBusinessUser>> {
    return this.http.get<IHttpPagedResponse<IBusinessUser>>(
      `${this.baseUrl}${this.businessAPIs.businessUsers.replace(
        '{businessId}',
        businessId
      )}`
    );
  }

  removeBusinessUser(
    businessUserId: string
  ): Observable<IHttpSingleDataResponse<boolean>> {
    return this.http.get<IHttpSingleDataResponse<boolean>>(
      `${this.baseUrl}${this.businessAPIs.removeBusinessUser(businessUserId)}`
    );
  }

  addBusinessByTin(
    tinNumber: string
  ): Observable<IResponse<AddBusinesResponseDTO>> {
    return this.http.post<IResponse<AddBusinesResponseDTO>>(
      `${this.baseUrl}/${this.businessAPIs.linkBusiness}`,
      { tinNumber: tinNumber }
    );
  }

  verifyBusinessToken(
    tinNumber: string,
    token: string
  ): Observable<IResponse<VerifyBusinesResponseDTO>> {
    return this.http.post<IResponse<VerifyBusinesResponseDTO>>(
      `${this.baseUrl}/${this.businessAPIs.verifyBusinessToken}`,
      { tinNumber: tinNumber, token: token }
    );
  }

  initiateAddUserRequest(
    data: BusinessUserRequestDTO
  ): Observable<IResponse<BusinessUserRequest>> {
    return this.http.post<IResponse<BusinessUserRequest>>(
      `${this.baseUrl}${this.businessAPIs.initiateAddUserRequest}`,
      data
    );
  }

  getUserRequests(
    businessId: string,
    queryParams: {
      page: number;
      size: number;
      sort: string;
    }
  ): Observable<IHttpPagedResponse<BusinessUserRequest>> {
    const queryParamsStr = convertObjectToQueryString(queryParams);
    return this.http.get<IHttpPagedResponse<BusinessUserRequest>>(
      `${this.baseUrl}${this.businessAPIs.listUserRequests(
        businessId
      )}?${queryParamsStr}`
    );
  }

  getUserAvailablePermissionsInBusiness(
    businessUserId: string
  ): Observable<
    IHttpSingleDataResponse<IBaseBusinessPermissionAndGroupEntity[]>
  > {
    return this.http.get<
      IHttpSingleDataResponse<IBaseBusinessPermissionAndGroupEntity[]>
    >(
      `${this.baseUrl}${this.businessAPIs.userAvailablePermissionsInBusiness(
        businessUserId
      )}`
    );
  }

  addNewBusinessUser(
    businessId: string,
    username: string,
    permissionGroupCode: string
  ): Observable<IHttpSingleDataResponse<any>> {
    const usernameType: 'EMAIL_ADDRESS' | 'PHONE_NUMBER' = username.includes(
      'a'
    )
      ? 'EMAIL_ADDRESS'
      : 'PHONE_NUMBER';

    const data: IAddBusinessUserDTO = {
      businessId,
      username,
      usernameType,
      permissionGroupCode: permissionGroupCode,
    };
    return this.http.post<IHttpSingleDataResponse<any>>(
      `${this.baseUrl}${this.businessAPIs.addNewBusinessUser}`,
      data
    );
  }

  private getAllIremboPermissions(): Observable<
    IHttpSingleDataResponse<IBaseBusinessPermissionAndGroupEntity[]>
  > {
    return this.http.get<
      IHttpSingleDataResponse<IBaseBusinessPermissionAndGroupEntity[]>
    >(`${this.baseUrl}${this.businessAPIs.iremboBusinessPermissions}`);
  }

  private getAllIremboPermissionGroups(): Observable<
    IHttpSingleDataResponse<IBaseBusinessPermissionAndGroupEntity[]>
  > {
    return this.http.get<
      IHttpSingleDataResponse<IBaseBusinessPermissionAndGroupEntity[]>
    >(`${this.baseUrl}${this.businessAPIs.iremboBusinessPermissionGroups}`);
  }

  fetchAllIremboPermissionsAndPermissionGroups(): void {
    const permissionGroupSubject = new Subject();
    const permissionSubject = new Subject();
    this.getAllIremboPermissionGroups()
      .pipe(takeUntil(permissionGroupSubject))
      .subscribe({
        next: (
          response: IHttpSingleDataResponse<
            IBaseBusinessPermissionAndGroupEntity[]
          >
        ) => {
          this.iremboBusinessPermissionGroups$.next(response.data);
          permissionGroupSubject.complete();
          permissionGroupSubject.unsubscribe();
        },
        error: () => {
          this.toastService.show({
            body: this.translateService.instant(
              'Permission group definitions could not be retrieved. Please reload the page.'
            ),
            type: 'error',
          });
          permissionGroupSubject.complete();
          permissionGroupSubject.unsubscribe();
        },
      });

    this.getAllIremboPermissions()
      .pipe(takeUntil(permissionSubject))
      .subscribe({
        next: (
          response: IHttpSingleDataResponse<
            IBaseBusinessPermissionAndGroupEntity[]
          >
        ) => {
          this.iremboBusinessPermissions$.next(response.data);
          permissionSubject.complete();
          permissionSubject.unsubscribe();
        },
        error: () => {
          this.toastService.show({
            body: this.translateService.instant(
              'Permission definitions could not be retrieved. Please reload the page.'
            ),
            type: 'error',
          });
          permissionSubject.complete();
          permissionSubject.unsubscribe();
        },
      });
  }

  findIremboPermissionEntityByCode(
    code: string
  ): IBaseBusinessPermissionAndGroupEntity | undefined {
    return this.findCodeInEntityArray(
      code,
      this.iremboBusinessPermissions$.getValue()
    );
  }

  findIremboPermissionGroupEntityByCode(
    code: string
  ): IBaseBusinessPermissionAndGroupEntity | undefined {
    return this.findCodeInEntityArray(
      code,
      this.iremboBusinessPermissionGroups$.getValue()
    );
  }

  private findCodeInEntityArray(
    code: string,
    entityArray: IBaseBusinessPermissionAndGroupEntity[]
  ): IBaseBusinessPermissionAndGroupEntity | undefined {
    return entityArray.find((entity: IBaseBusinessPermissionAndGroupEntity) => {
      return entity.code.toLowerCase() === code.toLowerCase();
    });
  }

  processUserRequest(
    id: string,
    data: BusinessUserRequestActionDTO
  ): Observable<IResponse<boolean>> {
    return this.http.put<IResponse<boolean>>(
      `${this.baseUrl}/${this.businessAPIs.processUserRequest(id)}`,
      data
    );
  }
}
