export const environment = {
  production: true,
  authServiceUrl: 'https://hub-keycloak.uat.irembolabs.com',
  authRealm: 'irembo',
  authClientId: 'citizens-portal',
  authSuccessRedirectUrl: 'https://citizen-portal.uat.irembolabs.com',
  apiGatewayBaseUrl: 'https://citizen-gateway.uat.irembolabs.com',
  idleTimeOutDuration: 1800,
  idleTimeOutCountDown: 30,
  IREMBOPAY_CDN: 'https://dashboard.irembopay.com/assets/payment/inline.js',
  IREMBOPAY_TEST: true,
  locale: 'IremboPay.locale.FR',
  knowledgeBaseUrl: 'https://support.irembo.gov.rw/en/support/home',
  DEFAULT_LOCALE: 'en-US',
  iremboPrivacyPolicyUrl: '/legal-information',
  SURVEY_SPARROW_WIDGET_TOKEN: 'tt-270a2e',
  SURVEY_SPARROW_ACCOUNT: 'irembo.surveysparrow.com',
  SURVEY_SPARROW_SURVEY_NAME: 'irembogov-website-survey',
  PHONE_NUMBER_REGEX: '^07[2-9][0-9]{7}$',
  translationSourceUrl: 'https://citizen-gateway.uat.irembolabs.com',
};
