import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { IBusinessUser } from '../../../../../models/business/business-user.model';
import { BusinessService } from '../../../../../services/business.service';
import { Subject, finalize, takeUntil } from 'rxjs';
import { IHttpPagedResponse } from '../../../../../models/http/paginated-response.model';
import {
  IHttpSingleDataResponse,
  ToastService,
} from '@irembo-andela/irembogov3-common';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { UserBusinessService } from '../../../../../services/user-business.service';
import { Business } from '../../../../../models/business/business.model';
import { ERROR_MAP } from '../../../../../data/errorMap';
import { HttpErrorResponse } from '@angular/common/http';
import { UserProfileService } from '../../../../../services/user-profile.service';
import { IUserProfile } from '../../../../../models/user-profile.model';
import { IBaseBusinessPermissionAndGroupEntity } from '../../../../../models/business/business.enum';

@Component({
  selector: 'irembogov-list-business-users',
  templateUrl: './list-business-users.component.html',
  styleUrls: ['./list-business-users.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ListBusinessUsersComponent implements OnInit, OnDestroy {
  @Input() businessId!: string;
  @Input() parentReloadSubject: Subject<boolean> = new Subject();
  businessUsers: IBusinessUser[] = [];
  subscriptionsSubject: Subject<any> = new Subject();
  isLoadingBusinessUsers = false;
  isRemovingBusinessUser = false;
  userToBeRemoved?: IBusinessUser;
  userProfile!: IUserProfile;

  business: Business | undefined = undefined;
  userAvailablePermissions: IBaseBusinessPermissionAndGroupEntity[] = [];

  isFetchingUserPermissions = false;

  @ViewChild('removeBusinessUserModal')
  removeBusinessUserModal!: TemplateRef<unknown>;

  constructor(
    private businessService: BusinessService,
    private userBusinessService: UserBusinessService,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private toastService: ToastService,
    private userProfileService: UserProfileService
  ) {
    this.businessService.fetchAllIremboPermissionsAndPermissionGroups();
  }

  ngOnInit(): void {
    this.userBusinessService.userBusinessesSubjectObservable
      .pipe(takeUntil(this.subscriptionsSubject))
      .subscribe(() => {
        this.business =
          this.userBusinessService.findUserBusinessInBusinessesById(
            this.businessId
          );
      });

    this.userProfileService
      .getUserProfile()
      .pipe(takeUntil(this.subscriptionsSubject))
      .subscribe((profile: IUserProfile) => {
        this.userProfile = profile;
      });

    this.getBusinessUsers();
    this.parentReloadSubject
      .pipe(
        takeUntil(this.subscriptionsSubject),
        finalize(() => (this.isLoadingBusinessUsers = false))
      )
      .subscribe(() => this.getBusinessUsers());
  }

  getBusinessUsers(): void {
    this.isLoadingBusinessUsers = true;
    this.businessService
      .getBusinessUsers(this.businessId)
      .pipe(
        takeUntil(this.subscriptionsSubject),
        finalize(() => (this.isLoadingBusinessUsers = false))
      )
      .subscribe({
        next: (response: IHttpPagedResponse<IBusinessUser>) => {
          this.businessUsers = response.data.content;
        },
      });
  }

  initRemoveBusinessUser(businessUser: IBusinessUser): void {
    if (!businessUser) {
      return;
    }
    this.userToBeRemoved = businessUser;
    this.getUserBusinessAvailablePermissions(businessUser.guid);

    const options: NgbModalOptions = {
      ariaLabelledBy: 'remove-business-user',
      windowClass: 'remove-business-user-modal',
      centered: true,
      container: '.irembogov-list-business-users',
      backdrop: 'static',
    };
    this.modalService.open(this.removeBusinessUserModal, options).result.then(
      () => {
        this.userToBeRemoved = undefined;
      },
      () => {
        this.userToBeRemoved = undefined;
      }
    );
  }

  getUserBusinessAvailablePermissions(businessUserId: string): void {
    this.isFetchingUserPermissions = true;
    this.userAvailablePermissions = [];
    this.businessService
      .getUserAvailablePermissionsInBusiness(businessUserId)
      .pipe(
        takeUntil(this.subscriptionsSubject),
        finalize(() => (this.isFetchingUserPermissions = false))
      )
      .subscribe({
        next: (
          response: IHttpSingleDataResponse<
            IBaseBusinessPermissionAndGroupEntity[]
          >
        ) => {
          this.userAvailablePermissions = response.data;
        },
        error: () => {
          this.userAvailablePermissions = [];
          this.toastService.show({
            body: this.translateService.instant(
              'Permissions for this user could not be retrieved. Please try again!'
            ),
            type: 'error',
            delay: 5000,
          });
          this.modalService.dismissAll();
        },
      });
  }

  removeBusinessUser(businessUserId?: string): void {
    if (!businessUserId) {
      return;
    }
    this.businessService
      .removeBusinessUser(businessUserId)
      .pipe(
        takeUntil(this.subscriptionsSubject),
        finalize(() => (this.isRemovingBusinessUser = false))
      )
      .subscribe({
        next: () => {
          this.toastService.show({
            body: this.translateService.instant(
              'Team member successfully removed!'
            ),
            type: 'success',
            delay: 5000,
          });

          this.getBusinessUsers();

          this.modalService.dismissAll();
        },
        error: (error: HttpErrorResponse) => {
          const errorMessage =
            ERROR_MAP[<string>error.error.responseCode] ??
            error?.error?.responseMessage ??
            error?.error?.message ??
            'The new team member has not been removed.';
          this.toastService.show({
            title: this.translateService.instant('Team member removal failed'),
            body: errorMessage,
            type: 'error',
            delay: 5000,
          });
        },
      });

    return;
  }

  ngOnDestroy(): void {
    this.subscriptionsSubject.complete();
    this.subscriptionsSubject.unsubscribe();
  }
}
